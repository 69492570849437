import React, { useState, useEffect, useRef } from "react";
import { models, service, factories, Embed } from 'powerbi-client';
import { useActiveTabs, useAuth } from "../../providers";
import { LoaderComponent } from "../../components";
import { v4 as uuidv4 } from 'uuid';
import { ChatGPT } from "../ChatGPT";
import { useToggle } from "@uidotdev/usehooks";
import { useChatGPT } from "../ChatGPT/providers";


export const PowerBI = ({ report, id }: { report: string, id?: number }) => {
  const { token } = useAuth()
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState<string>('');
  const configTimeRef = useRef<Date | null>(null);
  const hasRendered = useRef(false);
  const { closeTab } = useActiveTabs()
  const reportRef = useRef<HTMLDivElement>(null);
  const [isBootstrapped, setIsBootstrapped] = useState(false);

  const handleRenderComplete = () => {
    if (!hasRendered.current) {
      hasRendered.current = true;
      const now = new Date();
      setLoading(false);
      console.log(configTimeRef.current);
      if (configTimeRef.current) {
        fetch(`/api/dashboard/report-rendered`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({
            sessionId: sessionId,
            renderTime: (now.getTime() - configTimeRef.current.getTime()) / 1000  // Calculate render time in seconds
          })
        });
      }
    }
  };

  useEffect(() => {
    const newSessionId = uuidv4();
    setSessionId(newSessionId);

    fetch(`/api/dashboard/${report}/${newSessionId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then(async (data) => {
        configTimeRef.current = new Date();
        const config = {
          type: "report",
          id: data.reports[0].id,
          embedUrl: data.reports[0].embedUrl,
          accessToken: data.token,
          tokenType: models.TokenType.Embed,
          settings: {
            filterPaneEnabled: false,
            navContentPaneEnabled: false,
          },
        }

        // Create a Power BI service instance
        const powerbi = new service.Service(
          factories.hpmFactory,
          factories.wpmpFactory,
          factories.routerFactory
        )

        // Bootstrap the report if not already bootstrapped
        if (reportRef.current && !isBootstrapped) {
          powerbi.bootstrap(reportRef.current, config);
          // Get the embed instance
          const embed = powerbi.get(reportRef.current) as Embed;

          // Add event handlers
          embed.off('loaded');
          embed.off('rendered');
          embed.off('error');

          embed.on('loaded', () => {
            console.log('Report loaded');
          });

          embed.on('rendered', () => {
            handleRenderComplete()
            console.log('Report rendered');
          });

          embed.on('error', (event) => {
            console.error('Error loading report:', event.detail);
          });

          setIsBootstrapped(true);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
  }, [report, token, isBootstrapped]);

  useEffect(() => {
    const startTime = Date.now();
    const timeout = 2700000; // 60000*45 = 45 minutes

    function checkElapsedTime() {
      const elapsedTime = Date.now() - startTime;

      if (elapsedTime >= timeout) {
        if (id) {
          closeTab(id);
        }
      } else {
        requestIdleCallback(checkElapsedTime);
      }
    }

    requestIdleCallback(checkElapsedTime);

    return () => {
    };
  }, [id, closeTab]);

  const {isSidebarOpen, toggleSidebar} = useActiveTabs()
  const { tabState } = useActiveTabs()
  const { chatStatus, setChatStatus } = useChatGPT();

  const openAssistant = () => {
    if (isSidebarOpen){
      toggleSidebar()
    }
    setChatStatus("ASSISTANT_OPEN")
  }

  return (
    <>
      <div onClick={openAssistant} className="absolute w-36 h-14 text-white bg-text-primary-light right-4 top-4 rounded-2xl flex items-center justify-center hover:bg-white border border-text-secondary-light hover:text-text-secondary-light cursor-pointer">
        <h4 className="font-trendaSemiBold ">Assistant AI</h4>
      </div>
      <div className="flex w-full h-full">

        <LoaderComponent loadingConfig={!isBootstrapped && loading}>
          <></>
        </LoaderComponent>
        <div ref={reportRef} className={`report-container flex flex-col w-full items-center ${!(!isBootstrapped && loading) && "h-screen"}`} id="capture-area">
        </div>
        {
          chatStatus == "ASSISTANT_OPEN" &&
          <div className="flex w-[512px] shadow-lg bg-primary-light border-l border-[#F6F6F6] rounded-l-lg">
            <ChatGPT />
          </div>
        }
      </div>
    </>

  );
};

export default PowerBI;
