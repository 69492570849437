import { createContext, useContext, useEffect, useState } from "react";
import { useDataQuality } from "../hooks/useDataQuality";
import { useAuth } from "../../../providers";

interface DataGouvContextType {
    buSelected: string | null
    setBuSelected: React.Dispatch<React.SetStateAction<string | null>>
    dateSelected: string | null;
    setDateSelected: React.Dispatch<React.SetStateAction<string | null>>
    dataQuality: any;
    buOptions: string[];
    dateOptions: string[];
    getQualityDetailForBu: (asset: string, use_case: string, year: number) => Promise<any>;
}

const DataGouvContext = createContext<DataGouvContextType | undefined>(undefined);


export const DataGouvProvider = ({children}: React.PropsWithChildren) =>{
    const [buSelected, setBuSelected] = useState<string | null>(null)
    const [dateSelected, setDateSelected] = useState<string | null>(null)
    const [buOptions, setBuOptions] = useState<string[]>([])
    const [dateOptions, setDateOptions] = useState<string[]>([])
    const {token} = useAuth()
    const {dataQuality,getQualityDetailForBu} = useDataQuality(token)

    useEffect(()=>{
        if(dataQuality?.fields?.assets){
            const dates = Object.keys(dataQuality.fields.assets)
            setDateOptions(dates)

            if(dateSelected && dataQuality.fields.assets[dateSelected]){
                setBuOptions(dataQuality.fields.assets[dateSelected])
            }
        }

    },[dataQuality,dateSelected])

    useEffect(() => {
        if (dateOptions.length > 0 && !dateSelected) {
            setDateSelected(dateOptions[0]);
        }
    }, [dateOptions]);

    useEffect(() => {
        if (buOptions.length > 0 && !buSelected) {
            setBuSelected(buOptions[0]);
        }
    }, [buOptions]);

    
    return(
        <DataGouvContext.Provider value={{
            buSelected,
            setBuSelected,
            dateSelected,
            setDateSelected,
            dataQuality,
            buOptions,
            dateOptions,
            getQualityDetailForBu,
        }}>
            {children}
        </DataGouvContext.Provider>
    )
}

export const useDataGouv = () =>{
    const context = useContext(DataGouvContext)
    if (!context) {
        throw new Error('useDataGouv must be used within a DataGouvProvider');
    }
      return context;
}