import React, { useState } from "react";
import { useChatGPT } from "../providers";

export const ChatGPTContext = ({ }) => {
    const { gptConfig } = useChatGPT()
    const [inputValue, setInputValue] = useState(gptConfig.contextGPT);

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        console.log(event.target.value)
        setInputValue(event.target.value);
    };

    const handleSubmit = async () => {
        await gptConfig.setContext(inputValue)
    };

    return (
        <div className="w-full whitespace-nowrap rounded-xl text-left md:whitespace-normal">
            <div className="w-full">
                <textarea
                    className=" mb-2 p-2 rounded-md shadow-lg bg-[#ececec] w-full focus:outline-none"
                    value={inputValue}
                    onChange={handleInputChange}
                    style={{ height: '100px', minHeight: '100px', overflowY: 'auto', whiteSpace: 'pre-wrap' }}
                />
                <div className="flex justify-end w-full">
                    <button onClick={handleSubmit} className={`flex min-w-20 justify-center mb-4 px-4 py-2 text-black rounded-lg border border-gray-300 hover:text-emerald-500 hover:border-emerald-500 focus:outline-none ${gptConfig.contextGPTLoader ? "bg-emerald-500" : "bg-white"}`}>
                        {
                            gptConfig.contextGPTLoader ?
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg> : "Submit"
                        }
                    </button>
                </div>

            </div>
        </div>
    )
}