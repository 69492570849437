import { SVGIcon, Tabs } from "../../assets";
import { useActiveTabs } from "../../providers";
import { useCredit } from "../../providers";
import { forbiddenApps } from "../../constants/constant";
import { DotIcon } from "../../pages/ChatGPT/components";
import { useRef, useState } from "react";
import { useHover } from "../../hooks";
import { useChatGPT } from "../../pages/ChatGPT/providers";

export const SideBarTabs = ({
  tabs,
  isSidebarOpen,
  isBelongToSection,
}: {
  tabs: Tabs;
  isSidebarOpen: Boolean;
  isBelongToSection: Boolean;
}) => {
  const { openTab, tabState, closeTab, } = useActiveTabs();
  const { remainingCredit } = useCredit();
  const divRef = useRef(null);
  const { isHover } = useHover(divRef);
  const { homeStatus, setHomeStatus } = useChatGPT()

  // Base styles
  const baseStyles = `${isBelongToSection ? "py-2 px-3 w-full" : "py-1 px-2"} flex items-center rounded-lg text-text-primary-light`;
  const sidebarOpt = isSidebarOpen ? "w-full" : "justify-left";
  const hoverOpt = "hover:bg-secondary-light hover:text-text-secondary-light hover:opacity-100";

  // Disabled state styles
  const disabledStyles = typeof remainingCredit === 'number' && remainingCredit <= 0 && forbiddenApps.includes(tabs.appId)
    ? 'cursor-not-allowed bg-gray-200'
    : '';

  // Button styles based on active state
  const tabsBtnClassName = `${baseStyles} ${sidebarOpt} ${disabledStyles} ${!isHover && "opacity-50"}`;
  const tabsBtnActiveClassName = `${baseStyles} ${sidebarOpt} ${(tabState.activeTabId !== 9 || !homeStatus) && "bg-secondary-light"} text-text-secondary-light opacity-100`;

  // Conditional class for tab container
  const containerClasses = `
    flex items-center justify-center
    ${!isSidebarOpen && !isBelongToSection && "flex-col items-center"}
    ${isSidebarOpen && "w-full"}
    ${disabledStyles}
    ${isSidebarOpen && ((tabs.id === tabState.activeTabId) && (tabState.activeTabId !== 9 || !homeStatus) ? `bg-secondary-light text-text-secondary-light opacity-100 rounded-lg` : `${hoverOpt} rounded-lg`)}
  `;

  // Conditional class for button
  const buttonClasses = `
    flex ${isSidebarOpen && "w-full"} items-center justify-between
    ${tabs.id === tabState.activeTabId || (!isBelongToSection && !isSidebarOpen && isHover) ? tabsBtnActiveClassName : tabsBtnClassName}
    ${isHover && "opacity-100"}
    ${(tabs.id === tabState.activeTabId) && (tabState.activeTabId !== 9 || !homeStatus) ? `bg-secondary-light text-text-secondary-light opacity-100 rounded-lg` : `${hoverOpt} rounded-lg`}
  `;

  // Icon and text styles
  const iconTextClasses = `
    text-sm
    ${!isBelongToSection ? "pl-3" : "pl-1"}
    ${!isBelongToSection && !isSidebarOpen && isHover && "absolute z-50 rounded-md shadow-lg bg-white top-0 text-left left-[70px] border p-2 w-44"}
  `;

  const { setChatStatus } = useChatGPT()

  return (
    <div className={containerClasses} ref={divRef}>
      <button
        disabled={disabledStyles.length > 0}
        onClick={() => {
          openTab(tabs);
          setHomeStatus(false);
          if (tabs.appId.includes("Dashboard")) {
            setChatStatus("ASSISTANT_CLOSE")
          } else {
            setChatStatus("CLASSIC")
          }
        }}
        className={buttonClasses}
      >
        <div className={`flex ${!isSidebarOpen && !isBelongToSection && "pt-1"}`}>
          {!isBelongToSection && <span>{tabs.icon}</span>}
          {(isSidebarOpen || isBelongToSection || (!isBelongToSection && !isSidebarOpen && isHover)) && (
            <span className={iconTextClasses}>{tabs.title}</span>
          )}
        </div>
      </button>
      {
        tabs.id !== 9 && tabState.tabs.some(tab => tab.id === tabs.id) && (
          <button
            key={tabs.id}
            onClick={() => { closeTab(tabs.id); setChatStatus("CLASSIC") }}
            className={`
            ${tabs.id !== tabState.activeTabId && !isHover && "opacity-50"}
            ${!isSidebarOpen && !isBelongToSection ? "" : "pr-2"}
            `}
          >
            {isHover ? <SVGIcon icon="CLOSE_ICON" size="16px" /> : <DotIcon />}
          </button>
        )}
    </div>
  );
};
