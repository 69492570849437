import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../../providers';
import { LoaderComponent, LoaderSDH } from '../../components';

export const AIFactory = ({ appName }: { appName: string }) => {
  const [streamlitUrl, setStreamlitUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      const awakeStreamlit = async () => {
        try {
          const pingResponse = await fetch(`/api/streamlit/ping/single`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              appName: appName
            }),
          });

          if (pingResponse.ok) {
            const response = await fetch(`/api/streamlit/url?appName=${appName}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });

            const data = await response.json();
            const iframeUrl = data.url;
            setStreamlitUrl(iframeUrl);
            setLoading(false);
          } else {
            console.error(`Le ping a échoué avec le statut ${pingResponse.status}`);
            setLoading(false);
          }
        } catch (error) {
          console.error('Erreur lors de la récupération de l\'URL Streamlit :', error);
          setLoading(false);
        }
      };

      awakeStreamlit();
    }
  }, [token]);

  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <LoaderComponent loadingConfig={loading}>
        <></>
      </LoaderComponent>
      {streamlitUrl && (
        <iframe
          ref={iframeRef}
          src={streamlitUrl}
          style={{ height: "100%", width: "100%" }}
          title="Streamlit App"
          className={`border border-gray-300 rounded-md ${loading && "hidden"}`}
        />
      )}
    </div>
  );
};

export default AIFactory;