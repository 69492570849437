import { useAuth } from "../../../providers";
import { useChatGPT } from "../providers";
import { NewChatBtn } from "./NewChatBtn";

export const ChatGPTTop = ({ }: {}) => {
    const { tokenInfo } = useAuth();
    const firstLetterName = tokenInfo?.name.substring(0, 1).toUpperCase() || "P"

    const {
        modalStatus,
        setModalStatus,
        gptModel,
        gptConfig,
        chatStatus,
        setChatStatus
    } = useChatGPT();


    const title = !gptConfig.mDModeActive ? gptModel.chatModelOpt.find(chat => chat.id === gptModel.selectedChatModel)?.title : "MultiDocs";

    const closeAssistant = () => {
        setChatStatus("ASSISTANT_CLOSE")
    }

    return (
        <div className={`sticky top-0 p-3 mb-1.5 grid grid-cols-2 items-center justify-between z-10 h-14 ${chatStatus == "ASSISTANT_OPEN" ? "bg-text-primary-light" : "bg-white"}`}>
            <div className={`flex`}>
                <div onClick={() => { chatStatus != "ASSISTANT_OPEN" && setModalStatus("MODEL") }} id="radix-:r97:" aria-haspopup="menu" aria-expanded="false" data-state="closed" className={`${chatStatus == "ASSISTANT_OPEN" ? "" : "hover:bg-gray-100 cursor-pointer"} text-[#0d3d62] group flex items-center gap-1 rounded-lg py-1.5 px-3  overflow-hidden ${modalStatus == "MODEL" && "bg-gray-100"}`}>
                    <div className={`${chatStatus == "ASSISTANT_OPEN" ? "text-white" : "text-[#0d3d62]"} font-medium text-lg whitespace-nowrap font-trendaSemiBold`}>
                        {chatStatus == "ASSISTANT_OPEN" ? "AI Assistant" : title}
                    </div>
                    {
                        chatStatus != "ASSISTANT_OPEN" &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 24 24" className="icon-md text-token-text-tertiary"><path fill="currentColor" fillRule="evenodd" d="M5.293 9.293a1 1 0 0 1 1.414 0L12 14.586l5.293-5.293a1 1 0 1 1 1.414 1.414l-6 6a1 1 0 0 1-1.414 0l-6-6a1 1 0 0 1 0-1.414" clipRule="evenodd"></path></svg>
                    }

                </div>
            </div>
            {
                chatStatus == "ASSISTANT_OPEN" ?
                    <div className="flex justify-end items-center gap-2">
                        <button className="flex h-8 w-8 items-center justify-right text-white" onClick={closeAssistant}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" className="icon-md">
                                <path fill="currentColor" fillRule="evenodd" d="M5.636 5.636a1 1 0 0 1 1.414 0l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414L13.414 12l4.95 4.95a1 1 0 0 1-1.414 1.414L12 13.414l-4.95 4.95a1 1 0 0 1-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 0 1 0-1.414" clipRule="evenodd"></path>
                            </svg>
                        </button>
                    </div>
                    :
                    <div className="flex justify-end items-center gap-2">
                        <NewChatBtn />
                        <button className="bg-text-primary-light w-10 h-10 flex items-center justify-center text-lg rounded-full text-white" onClick={() => { setModalStatus("PARAMS") }}>
                            <span className="">{firstLetterName}</span>
                        </button>
                    </div>
            }

        </div>
    )
}
