import { useEffect, useState } from "react"
import { DataTableQuality } from "./DataTable"
import { useDataGouv } from "./providers/DataGouvProvider"
import { useDataQuality } from "./hooks/useDataQuality"
import { useAuth } from "../../providers"
import { SelectOptions } from "../DocumentTranslator/SelectedOptions"

interface BUProps {
    title: string
}

const filterOptions = ['processing', 'failed', 'success']

const navOpt = [
    {
        id: 'Quality',
        title: "Quality",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Ingestion',
        title: "Ingestion",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'Ref',
        title: "Referentiel",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    },
    {
        id: 'DataLin',
        title: "Data Linéage",
        bgColor: "bg-gradient-to-br from-[#3399B1] to-[#116B81]",
    }
]

export const BUMainApp: React.FC<BUProps> = ({title}) => {
    const {buSelected, setBuSelected, dateSelected, setDateSelected, buOptions, dateOptions, getQualityDetailForBu} = useDataGouv()
    const {token} = useAuth()
    const [app,setApp] = useState('Quality')
    const [dataForTable, setDataForTable] = useState<any[]>([])
    const [filterStatus, setFilterStatus] = useState<string | null>(null)
    const [loaderTable, setLoaderTable] = useState<boolean>(false)

    const setUpTable = async() =>{
        setLoaderTable(true)
        const data = await getQualityDetailForBu(buSelected!,title.toLocaleUpperCase(), parseInt(dateSelected!))
        setDataForTable(data)
        setLoaderTable(false)
    }

    useEffect(()=>{
        setUpTable()
    },[buSelected,dateSelected])

    const filteredData = filterStatus && filterStatus !== "All"
        ? dataForTable.filter(item => item.status === filterStatus)
        : dataForTable

    return(
        <div className="flex flex-col h-full items-center">
            <div className="flex flex-col items-center justify-center p-5 text-text-primary-light gap-2">
                <h1 className="font-trendaSemiBold text-3xl">
                    Data Gouvernance <span className="font-sans">- {buSelected} - Cas d'usage {title}</span>
                </h1>
                <div className="flex flex-row gap-3 w-full p-3">
                    <div className="flex w-full justify-center relative">
                        <div className="flex items-center border rounded-2xl w-fit cursor-pointer">
                            {
                                navOpt.map((e, i) => {
                                    const isCurrentApp = e.id === app;
                                    const bgColorClass = isCurrentApp ? `${e.bgColor} text-white` : 'text-[#2E2E2E] opacity-50';
                                    const transitionClass = isCurrentApp ? 'transition-all duration-300 ease-in-out' : '';

                                    return (
                                        <div key={i} className={`text-center px-4 py-1 ${bgColorClass} ${transitionClass} rounded-xl`} onClick={()=> setApp(e.id)}>
                                            <h3 className="font-medium">{e.title}</h3>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
                <p className="text-center w-[80%]">
                    In this section, you can manage the ingestion of files for the <span className="font-bold">{title}</span> use case, you can analyze the history of different types of errors by file type as well as an explanation.
                </p>
            </div>
            <div className="flex w-full">
                <div className="flex flex-row gap-3 w-full items-center justify-start pl-8">
                    <h1 className="text-text-primary-light">BU Selection:</h1>
                    <SelectOptions
                    options={buOptions}
                    defaultValue="BU Selection"
                    renderOption={(method) => ({ value: method, label: method })}
                    selectedValue={buSelected!}
                    onChange={(e) =>{setBuSelected(e.target.value)} }
                    />
                    <h1 className="text-text-primary-light">Year to Date:</h1>
                    <SelectOptions
                        options={dateOptions}
                        defaultValue="Year to Date"
                        renderOption={(method) => ({ value: method, label: method })}
                        selectedValue={dateSelected!}
                        onChange={(e) => {setDateSelected(e.target.value)}}
                    />
                </div>
                <div className="flex flex-row gap-3 w-full items-center justify-end pr-8">
                    <h1 className="text-text-primary-light">Filter by status: </h1>
                    <SelectOptions
                        options={filterOptions}
                        defaultValue="All"
                        renderOption={(method) => ({ value: method, label: method })}
                        selectedValue={filterStatus!}
                        onChange={(e) => {setFilterStatus(e.target.value)}}
                    />
                </div>
            </div>

            {app === 'Quality' ? 
            (
                <DataTableQuality data={filteredData} loader={loaderTable} />
            ) 
                : 
            (
                <div className="text-center">
                    <h2 className="text-2xl font-bold">{app} Section</h2>
                    <p>This section is under construction for {app}.</p>
                </div>
            )}
        </div>

    )
}