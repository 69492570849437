export interface SummaryBuProps {
    title: string;
    fileReceivedTotal: number;
    fileSucess: number;
    fileIngestionError: number;
    fileLateError: number;
}

export const SummaryBu: React.FC<SummaryBuProps> = ({title, fileReceivedTotal, fileIngestionError, fileLateError,fileSucess}) =>{
    return(
        <div className="flex flex-col p-4 bg-white w-full text-text-primary-light rounded-lg shadow-md cursor-pointer hover:shadow-lg hover:bg-secondary-light transition-shadow">
            <h2 className="text-2xl font-trendaSemiBold mb-2">{title}</h2>
            <p><span className="font-bold">{fileReceivedTotal}</span> files received in the last 12 month.</p>
            <p><span className={`font-bold ${fileIngestionError > 0 ? 'text-red-500' : ''}`}>{fileIngestionError}</span> have been refused because of an ingestion error.</p>
            <p><span className={`font-bold ${fileLateError > 0 ? 'text-red-500' : ''}`}>{fileLateError}</span> are late to be send.</p>
        </div>
    )
}