import { ChatGPTBottom, ChatGPTModalParams, ChatGPTModalChoiceModal, ChatGPTTalk, ChatGPTTop, ChatGPTModalDocument, ChatGPTModalParameters, getHistoryById } from "./components";
import { ChatGPTProvider, useChatGPT } from "./providers";
import { DocumentTranslator } from "../DocumentTranslator";
import { useEffect } from "react";
import { ChatGPTMiddleModalDocuments } from "./components/ChatGPTMiddleModalDocuments";
import { ChatGPTModal } from "./components/ChatGPTModal";
import { useDropzone } from "react-dropzone";
import { useActiveTabs } from "../../providers";

export const ChatGPT = () => {
  return (
    <ChatGPTContent />
  );
};

const ChatGPTContent = ({ }: {}) => {

  const {
    gptInput,
    gptGesture,
    homeStatus,
    gptConfig,
    chatStatus,
    setChatStatus
  } = useChatGPT();

  useEffect(() => {
    gptGesture.setIsMounted(true)
    gptInput.setIsMounted(true)
  }, [])
  /*
    const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections, open } = useDropzone({
      accept: {
          "application/pdf": [".pdf"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
          "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
          "text/plain": [".txt"],
      },
          maxFiles: 10,
          multiple: true,
          maxSize: 200 * 1024 * 1024, // 200MB in bytes
          noClick: true
      });
      console.log("isDragActive", isDragActive)
      */
  let mainClass = "relative h-full w-full flex-1 overflow-auto transition-width bg-white"
  if (chatStatus == "ASSISTANT_OPEN") {
    mainClass = "relative h-full w-full flex-1 overflow-auto transition-width bg-white pb-4"
  }
  const h = getHistoryById(gptConfig.securedGPTHistory, gptConfig.selectedChatId)
  const { tabState } = useActiveTabs()

  useEffect(()=>{
    if (tabState.activeTabId === 9 && chatStatus == "ASSISTANT_OPEN")
      setChatStatus("CLASSIC")

  }, [tabState.activeTabId])

  return (
    <>
      <ChatGPTModal />
      <main className={mainClass}>
        <div className="flex h-full flex-col focus-visible:outline-0 bbg-red-400">
          <ChatGPTTop />
          <div className="flex-1 overflow-hidden h-full">
            <div className="h-full overflow-auto">
              <div className={`flex flex-col text-sm md:pb-9 ${!homeStatus && h && h.length == 0 && "h-full"} bg-primary-light`}>
                <ChatGPTTalk />
              </div>
            </div>
          </div>
          <ChatGPTBottom />
        </div>
      </main>
    </>);
};

export default ChatGPT;