import React, { useState } from 'react';

interface DataTableProps {
  data: {
    file_type: string;
    file_name_sftp: string;
    reception_time: string;
    status: string;
    reception_frequency: number;
    cause?: string;
    detailed_cause?: string;
  }[];
  loader: boolean;
}

export const DataTableQuality: React.FC<DataTableProps> = ({ data, loader }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedCause, setSelectedCause] = useState<string | null>(null);
  const [selectedDetailedCause, setSelectedDetailedCause] = useState<string | null>(null);

  const handleDownload = (fileName: string) => {
    alert(`Downloading file: ${fileName}`);
  };

  const getStatusColor = (status: string) => {
    if (status === 'failed') return 'bg-red-500 text-white';
    if (status === 'processing') return 'bg-yellow-400 text-black';
    if (status === 'success') return 'bg-green-500 text-white';
    return '';
  };

  const openModal = (cause: string, detailed_cause: string) => {
    setSelectedCause(cause);
    setSelectedDetailedCause(detailed_cause);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedCause(null);
    setSelectedDetailedCause(null);
  };

  return (
    <div className="overflow-x-auto w-full px-8 py-2">
      {loader ? (
        <div className="text-center py-4">
          <p className='text-md'>Loading data...</p>
        </div>
      ) : data.length === 0 ? (
        <div className="text-center py-4 text-gray-600">
          <p>No data available</p>
        </div>
      ) : (
        <table className="min-w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-200">
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                Status
              </th> 
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                File Type
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                File Name
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                Upload Date
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                Reception Frequency
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                Cause
              </th>
              <th className="border border-gray-300 px-4 py-2 text-left font-bold text-gray-700">
                Download
              </th>
            </tr>
          </thead>
          <tbody className="text-xs">
            {data.map((item, index) => (
              <tr
                key={index}
                className={`${getStatusColor(item.status)} border border-gray-300 ${
                  item.status === 'failed' ? 'cursor-pointer' : ''
                }`}
                onClick={() =>
                  item.status === 'failed' &&
                  item.cause &&
                  item.detailed_cause &&
                  openModal(item.cause!, item.detailed_cause!)
                }
              >
                <td
                  className={`border border-gray-300 px-4`}
                  onClick={() =>
                    item.status === 'failed' &&
                    item.cause &&
                    item.detailed_cause &&
                    openModal(item.cause!, item.detailed_cause!)
                  }
                >
                  {item.status}
                </td>
                <td className="border border-gray-300 px-4">{item.file_type}</td>
                <td className="border border-gray-300 px-4">{item.file_name_sftp}</td>
                <td className="border border-gray-300 px-4">{item.reception_time}</td>
                <td className="border border-gray-300 px-4">{item.reception_frequency}</td>
                <td className="border border-gray-300 px-4">{item.cause}</td>
                <td className="border border-gray-300 px-4 text-center">
                  <button
                    onClick={() => handleDownload(item.file_name_sftp)}
                    className="p-2 hover:bg-gray-200 rounded-md"
                  >
                    {/* Download icon */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 16v2a2 2 0 002 2h12a2 2 0 002-2v-2M7 10l5 5m0 0l5-5m-5 5V4"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Modal for Ingestion Error */}
      {showModal && selectedCause && selectedDetailedCause && (
        <div className="absolute inset-0 backdrop-blur-sm flex items-center justify-center">
          <div className="bg-white p-6 rounded-md shadow-md w-[50%]">
            <h2 className="text-xl font-bold mb-4">Ingestion Error Details</h2>
            <p><strong>Cause:</strong> {selectedCause}</p>
            <p><strong>Detailed Cause:</strong> {selectedDetailedCause}</p>
            <button onClick={closeModal} className="mt-4 p-2 bg-red-500 text-white rounded-md">
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
